import { styled } from '@mui/system'
import { Box } from '@mui/material'

export const FormLabel = styled('label')(props => `
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 600;
  color: ${props?.theme?.palette?.primary?.dark};
`)

export const FileName = styled('label')(props => `
  padding-top:5px;
  font-size: 12px;
  color: ${props?.theme?.palette?.primary?.dark};
`)

export const FilePreviewWrapper = styled(Box)(`
  display:flex;
`)

export const FilePreview = styled(Box)(`
  border: 1px #d3d3d3 solid;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  display:flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  cursor: pointer;
`)

export const TextFieldWrapper = styled(Box)(({ theme }) => `
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 0 8px;
`)

export const LabelWrapper = styled(Box)(`
    display: flex;
    justify-content: space-between;
    align-items: center;
`)

export const FormReq = styled('span')(`
  font-size: 12px;
  color: #DA2A52;
  padding-left:5px;
`)

export const FormError = styled(Box)(`
  width: 100%;
  display: block;
  line-height: 20px;
  font-size: 0.75rem;
  font-weight: 400;
  margin: 4px 14px 0 0;
  color: #D14343;
  background-color: transparent !important;
`)
