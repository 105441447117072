import React, { useMemo } from 'react'
import { ACTIONS } from '../actions'
import { reducers } from '../reducer'

const initialState = {
  employeeList: []
}

export const useEmployeeProvider = () => {
  const [state, dispatch] = React.useReducer(reducers.employeeReducer, initialState)

  const value = useMemo(() => {
    return {
      employeeList: state.employeeList,
      stateAddEmployee: (object) => {
        dispatch({ type: ACTIONS.ADD_EMPLOYEE, object })
      }
    }
  }, [state])

  return value
}
