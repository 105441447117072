import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { ROUTES, COMMON } from '../../../constants'
import { Box } from '@mui/material'
import { LocationOn } from '@mui/icons-material/'
import JobService from '../../../services/jobOpenings.service'
import { useMuiSnackbar } from '../../../hooks'
import PostLogin from '../../../layout/post-login'
import { Title, MuiButton, MuiChip, Loader } from '../../../components/'
import {
  CardWrapper,
  CardInnerWrapper,
  ContentWrapper,
  Text,
  ContentHeading
} from './style'

const JobListPage = () => {
  const { SnackbarError } = useMuiSnackbar()

  const [gridLoader, setGridLoader] = useState(true)
  const [departmentFilter, setDepartmentFilter] = useState(localStorage.getItem('job-department-filter') || 'all')
  const publishFilter = 'all'
  const [departmentTabs, setDepartmentTabs] = useState([
    {
      label: 'All',
      value: 'all',
      active: departmentFilter === 'all',
      onClick: () => { departmentFilterHandler('all') }
    }
  ])

  const [jobData, setJobData] = useState([])

  const filterFunc = (arr) => {
    const filtered = arr
      .filter(f => departmentFilter !== 'all' ? f?.department?._id === departmentFilter : true)
      .filter(f => publishFilter !== 'all' ? f?.publishType === publishFilter : true)
    return filtered
  }

  const tableData = {
    title: 'job list',
    view: ROUTES.ROUTE_JOB_VIEW,
    edit: ROUTES.ROUTE_JOB_FORM,
    cols: [
      { title: 'heading' },
      { title: 'department' },
      { title: 'candidates' },
      { title: 'location' },
      { title: 'job Type' },
      { title: 'status' }
    ],
    srNo: true,
    rows: filterFunc(jobData)
  }

  const loadJobData = () => {
    JobService.getJobList()
      .then(response => {
        setJobData(response?.data)
      })
      .catch(err => {
        SnackbarError(err)
      })
      .finally(() => {
        setGridLoader(false)
      })
  }

  const departmentFilterHandler = (id) => {
    setDepartmentFilter(id)
    setDepartmentTabs(
      prev => prev.map((d) => {
        return {
          ...d,
          active: !!(id === (d?.value || 'all'))
        }
      })
    )
    localStorage.setItem('job-department-filter', id)
  }

  useEffect(() => {
    JobService.getDepartmentList()
      .then((resp) => {
        if (resp.data.length === 0) {
          SnackbarError('There are no Departments')
          return
        }
        const menu = resp.data.map(d => {
          return {
            label: d.name,
            value: d._id,
            onClick: () => { departmentFilterHandler(d._id) }
          }
        })
        setDepartmentTabs([...departmentTabs, ...menu])
        departmentFilterHandler(departmentFilter)
      })
      .catch((error) => {
        SnackbarError(error)
      })
  }, [])

  useEffect(() => {
    loadJobData()
  }, [])

  return (
    <PostLogin heading='Job' fixed>
      <Title tabs={departmentTabs} />
      {tableData.rows.length > 0
        ? tableData.rows.map((row, i) => {
          return (
            <CardWrapper item key={row?._id}>
              <CardInnerWrapper>
                <ContentHeading>{row?.heading}</ContentHeading>
                <ContentWrapper>
                  <Text>{row?.department?.name}</Text>
                  <LocationOn sx={{ fontSize: 'inherit' }} color='primary' />
                  <Text>{row?.location?.name}</Text>
                </ContentWrapper>
                <MuiChip label={COMMON.VAL_TO_LABEL('JOB_TYPE', row, 'jobType')} color="secondary" variant="contained" size="small" />
              </CardInnerWrapper>
              <Box sx={{ width: '200px' }} >
                <MuiButton to={`${tableData?.view}/${row?._id}`} color="primary" variant='outlined' fullWidth size='Small' >View Details</MuiButton>
                <MuiButton to={`${ROUTES.ROUTE_CANDIDATE_FORM}?departmentId=${row?.department?._id}&jobId=${row?._id}`} color="primary" variant='contained' fullWidth size='small' >Apply</MuiButton>
              </Box>
            </CardWrapper>
          )
        })
        : <Loader showLoader={gridLoader} showMessage={!gridLoader && true} message='No data found!' />
      }
    </PostLogin>
  )
}

JobListPage.propTypes = {
  setAgentDataPage: PropTypes.func,
  setAgentDataLimit: PropTypes.func,
  agentDataPage: PropTypes.func,
  agentDataLimit: PropTypes.func
}

JobListPage.displayName = 'Job List'

export default JobListPage
