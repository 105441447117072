import React from 'react'
import PreLogin from '../../layout/pre-login'
import { MuiButton } from '../../components/'

const title = '500'

const InternalServer = () => {
  return (
    <PreLogin titleBig={title} titleH4={'Oops.. You just found an error page..'} contentp={'We are sorry but your request contains bad syntax and cannot be fulfilled…'}>
      <MuiButton
        margin='dense'
        variant='contained'
        size='xSmall'
        to={'/'}
        sx={{ mb: '15px' }}
      >
        go back
      </MuiButton>
    </PreLogin>
  )
}

export default InternalServer
