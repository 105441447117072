import { preLogin } from '../util/axios'

const API_URL = `${process.env.REACT_APP_API_URL}`
const apiName = 'job-openings'

const getJobList = (id) => {
  const url = `${API_URL}${apiName}`
  return preLogin.get(url)
}

const getOneJob = (id) => {
  const url = `${API_URL}${apiName}/jobs/${id}`
  return preLogin.get(url)
}

const dropdownUpdate = (data, id, type) => {
  const url = `${API_URL}${apiName}/dropdown/${id}?type=${type}`
  return preLogin.put(url, data)
}

const addCandidate = (data) => {
  const url = `${API_URL}${apiName}/`
  return preLogin.post(url, data)
}

const getDepartmentList = (id) => {
  const url = `${API_URL}${apiName}/departments`
  return preLogin.get(url)
}

export default {
  getJobList,
  getOneJob,
  dropdownUpdate,
  addCandidate,
  getDepartmentList
}
