import React from 'react'
import PropTypes from 'prop-types'
import {
  StatsTitle,
  StatsSubTitle,
  StatsWrapper,
  StatsBoxLink,
  CardWrapper,
  StatsBox
} from './style'

const StatsHolder = ({ data }) => {
  let counter = 0
  counter = data?.count
  // setInterval(() => {
  //   if (counter <= data?.count && data?.count > 0) {
  //     counter += 1
  //   }
  // }, 500)
  const color = data?.color ? `${data?.color}.main` : 'primary.dark'
  return (
    <>
      <StatsTitle variant='h6'>{data?.title}</StatsTitle>
      <StatsSubTitle color={color} variant='p'>{counter}</StatsSubTitle>
    </>
  )
}

const Stats = props => {
  return (
    <StatsWrapper {...props}>
      {props?.data?.map((data, i) => {
        const activeHandler = (e) => {
          data?.onClick()
        }
        return (
          <CardWrapper key={i}>
            {data?.to
              ? <StatsBoxLink key={i} onClick={(e) => { activeHandler(e) }} to={data?.to} className={data?.active && 'active'}>
                <StatsHolder data={data} />
              </StatsBoxLink>
              : <StatsBox key={i} onClick={(e) => { activeHandler(e) }} className={data?.active && 'active'}>
                <StatsHolder data={data} />
              </StatsBox>
            }
          </CardWrapper>
        )
      })}
    </StatsWrapper>
  )
}

StatsHolder.propTypes = {
  data: PropTypes.object
}

Stats.propTypes = {
  data: PropTypes.array,
  loader: PropTypes.bool
}

Stats.displayName = 'Stats'

export default Stats
