import { styled, Typography, Box } from '@mui/material'
import { Link } from 'react-router-dom'

const styling = (props) => `
  width: 100%;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: ${props?.theme?.components?.MuiPaper?.styleOverrides?.root?.borderRadius}px;
  background: white;
  transition: ease-in-out .2s;
  border: 1px solid transparent;
  // &:hover{
  //   transform: scale(1.05);
  // }
  &.active{
    border: 1px solid ${props?.theme?.palette?.primary?.main};
  }
`

export const CardWrapper = styled(Box)(props => `
  display: flex;
  width: 100%;
  margin-right: 15px;
  &:last-child{
    margin-right: 0;
  }
`)

export const StatsTitle = styled(Typography)(props => `
  text-transform: capitalize;
  font-size: 16px;
  color: ${props?.theme?.palette?.primary?.dark};
  font-weight: 400;
  line-height: calc(24 / 16);
`)

export const StatsSubTitle = styled(Typography)(props => `
  font-size: 28px;
  font-weight: 500;
  line-height: calc(33.6 / 28);
`)

export const StatsWrapper = styled(Box)(`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`)

export const StatsBox = styled(Box)(props => styling(props))

export const StatsBoxLink = styled(Link)(props => `
  ${styling(props)};
  cursor: pointer;
`)
