import { CheckBox, Add } from '@mui/icons-material'
import { styled, TableContainer, TableCell, TableBody, TableHead, TableRow, Paper, Box, Typography } from '@mui/material'

export const Checkboxed = styled(CheckBox)(`
  padding: 0px;
`)

export const TableBodyStyled = styled(TableBody)(`
  > tr {
    td:last-child{
      width: 105px;
    }
  }
`)

export const TableContainerStyled = styled(TableContainer)(`
`)

export const TableTitleWrapper = styled(Box)(`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7px 0px 38px;
  border: 0;
  background: none;
`)

export const StyledAdd = styled(Add)(`
  line-height: 1;
  font-size: 15px;
  font-weight: 400;
`)

export const TableTitle = styled(Typography)(`
  font-size: 15px;
  font-weight: 400;
  line-height: 1.2;
  text-transform: uppercase;
`)

export const Upside = styled(Typography)(`
  text-transform: capitaliz;
  display: flex;
  align-items: center;
`)

export const TableHeadStyled = styled(TableHead)(`
  background: white;
`)

export const TableRowStyled = styled(TableRow)(`
  &:nth-of-type(even){
    background: rgba(0,0,0,.04);
  }
  &:last-child{
    > td {
      border-bottom: none !important;
    }
  }
`)

const candidateStyling = (props) => `
  padding: 0;
  box-shadow: none;
  background: transparent !important;
  border-radius: 0;
  & table {
    border-collapse: separate;
    border-spacing: 0px 5px;
  }
  & thead {
    background: transparent !important;
    th {
      border: 0 !important;
      color: #292b3080 !important;
      padding :12px;
    }
  }
  & tr {
    border-radius: 8.8px;
    td {
      position: relative;
      border: 1px transparent solid;
      &:after{
        content: '';
        background: ${props?.theme?.palette?.background?.index};
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 1px;
      }
      &:first-of-type {
        border-radius: 8.8px 0 0 8.8px;
      }
      &:last-child {
        border-radius: 0 8.8px 8.8px 0;
          &:after{
            width: 0;
          }
      }
    }
  }
  & tbody {
    tr {
      background: #fff !important;
      border: 1px solid transparent;
      &.loader-tr {
        td {
          border-radius: 8.8px !important;
        }
      }
      &:hover {
        td {
          border-top: 1px solid ${props?.theme?.palette?.primary?.main} !important;
          border-bottom: 1px solid ${props?.theme?.palette?.primary?.main} !important;
          &:after {
            top: 50%;
            transform: translate(0, -50%);
          }
          &:first-of-type {
            border-left: 1px solid ${props?.theme?.palette?.primary?.main} !important;
          }
          &:last-child {
            border-right: 1px solid ${props?.theme?.palette?.primary?.main} !important;
          }
        }
      }
    }
    td {
      padding: 12px;
    }
  }
`

export const PaperStyled = styled(Paper)(props => `
  padding: 20px;
  ${props.paperStyle === 'candidateStyle'
    ? candidateStyling(props)
    : `table>tbody>tr:last-child>td{
        border-bottom: none !important;
      }
    `}
`)

export const Cell = styled(TableCell)(props => `
  background: none !important;
  border-bottom: ${props?.borWid ? props?.borWid : '1px'} solid #dee2e6 !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  width: ${props?.width || 'auto'};
  padding: 8px 12px 12px 12px;
`)
