import { styled, Box } from '@mui/material'

export const FormLabel = styled('label')(props => `
  text-transform: capitalize;
  font-size: 15px;
  font-weight: ${props?.bold ? '600' : '400'};
  color: ${props?.theme?.palette?.primary?.dark};
`)

export const LabelWrapper = styled(Box)(`
  display: flex;
  justify-content: space-between;
  align-items: center;
`)
