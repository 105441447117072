import { styled } from '@mui/system'
import { Box, TextareaAutosize, TextField } from '@mui/material'

export const FormLabel = styled('label')(({ theme }) => `
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 400;
  color: ${theme?.palette?.primary?.dark};
`)

export const TextFieldStyled = styled(TextField)(`
  margin: 0;
  margin-bottom: 16px;
  &::placeholder,
  *::placeholder{
    text-transform: capitalize;
  }
  & div > textarea {
    font-size: 14px;
  }
  & div > textarea::placeholder {
    font-size: 14px;
  }
  & div > fieldset {
    border-radius: 4px;
  }
  & div > input {
    border-radius: 4px;
    font-size: 14px;
    padding: 8px 13px;
    &::placeholder {
      font-size: 14px;
    }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &[type=number] {
      -moz-appearance: textfield;
    }
  }
`)

export const Wrapper = styled(Box)(({ theme }) => `
  padding: 0 8px;
`)

export const TextFieldWrapper = styled(Box)(({ theme }) => `
  display: flex;
  flex-direction: column;
  border-radius: 10px;
`)

export const FormReq = styled('span')(props => `
  font-size: 14px;
  color: ${props?.theme?.palette?.error?.main};
  // padding-left: 5px;
`)

export const IconWrap = styled(Box)(({ theme }) => `
  width:40px;
  height:40px;
  display: flex;
  justify-content: center;
  align-items: center;
  & img {
    max-width:20px;
    max-height:20px;
  }
`)

export const FormError = styled(Box)(`
  width:100%;
  display:block;
  line-height: 20px;
  font-size: 0.75rem;
  font-weight: 400;
  margin: 4px 14px 0 14px;
  color: #D14343;
  background-color: transparent !important;
`)

export const TextareaInput = styled(TextareaAutosize)(() => ({
  'label + &': {
    marginTop: 20
  },
  border: '1px solid #E4E7EC',
  fontSize: 14,
  height: 35,
  padding: '8px 16px',
  fontFamily: 'Poppins',
  boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.12)',
  width: '100%'
}))
