import React from 'react'
import {
  HeaderOuterWrapper,
  Container,
  HeaderInnerWrapper
} from './style'

const Header = () => {
  return (
    <HeaderOuterWrapper>
      <Container>
        <HeaderInnerWrapper>
          <p>Copyright © 2023 Apphubs.in</p>
          <p>Powered by Apphubs.in</p>
        </HeaderInnerWrapper>
      </Container>
    </HeaderOuterWrapper>
  )
}

export default Header
