import React, { useState, useRef } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { useFormik } from 'formik'
import { Grid } from '@mui/material'
import * as Yup from 'yup'
import PostLogin from '../../../layout/post-login'
import CandidateService from '../../../services/jobOpenings.service'
import { COMMON, ROUTES } from '../../../constants'
import { useMuiSnackbar } from '../../../hooks'
import { MuiTextField, MuiButton, MuiSelect, UploadFile } from '../../../components'
import { Form, GridWrapper, HeadingWrapper } from './style'
import { ButtonWrapper } from '../../common/style'

const CandidateAddPage = () => {
  const form = useRef()
  const useQuery = () => {
    return new URLSearchParams(useLocation().search)
  }
  const query = useQuery()
  const [isLoading, setIsLoading] = useState(false)
  const history = useNavigate()
  const { id } = useParams()
  const { SnackbarSuccess, SnackbarError } = useMuiSnackbar()

  const initialVal = {
    departmentId: query.get('departmentId'),
    firstName: '',
    lastName: '',
    find: '',
    currentCtc: '',
    expectedCtc: '',
    source: '',
    jobId: query.get('jobId'),
    mobile: null,
    email: '',
    resume: null
  }
  const mode = 'add'

  const validation = (values) => {
    const errors = {}
    return errors
  }

  const onSubmits = (data) => {
    setIsLoading(true)
    CandidateService.addCandidate(data)
      .then((resp) => {
        history(`${ROUTES.ROUTE_JOB_LIST}?departmentId=${formik?.values?.departmentId}&jobId=${formik?.values?.jobId}`)
        SnackbarSuccess('Candidate', mode)
      })
      .catch((error) => {
        SnackbarError(error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    initialValues: initialVal,
    validationSchema: Yup.object({
      departmentId: Yup.string().required(),
      firstName: Yup.string().max(25).required(),
      middleName: Yup.string().max(25),
      lastName: Yup.string().max(25).required(),
      jobId: Yup.string().required(),
      mobile: Yup.string().max(25).required(),
      find: Yup.string().max(250).required(),
      currentCtc: Yup.string().required(),
      expectedCtc: Yup.string().required(),
      source: Yup.string().required(),
      email: Yup.string().required(),
      resume: Yup.object({
        extension: Yup.string().required(),
        size: Yup.number().required(),
        fileName: Yup.string().required(),
        originalFileName: Yup.string().required()
      })
    }),
    validate: values => validation(values),
    onSubmit: values => {
      const updatedData = values
      onSubmits(updatedData)
    }
  })

  const onChangeResume = (e) => {
    if (e?.details) {
      const data = {
        extension: e.details.mimetype,
        size: e.details.size,
        fileName: e.details.filename,
        originalFileName: e.details.originalname
      }
      formik.setFieldValue('resume', data)
    } else {
      formik.setFieldValue('resume', null)
    }
  }

  const breadcrumbs = [
    {
      title: 'Job',
      to: ROUTES.ROUTE_JOB_LIST
    },
    {
      title: 'Candidates',
      to: ROUTES.ROUTE_CANDIDATE_LIST
    },
    {
      title: 'Candidates',
      to: `${ROUTES.ROUTE_CANDIDATE_LIST}?departmentId=${query.get('departmentId')}&jobId=${query.get('jobId')}`
    },
    {
      title: id ? 'Edit' : 'Add'
    }
  ]

  return (
    <PostLogin
      breadcrumbs={breadcrumbs}
      heading={`Candidate ${id ? 'Edit' : 'Add'}`}
      fixed
    >
      {/* <Title
        primaryButton={{
          title: 'back',
          size: 'small',
          onClick: () => {
            history(-1)
          }
        }}
      /> */}

      <HeadingWrapper>
        Apply!
      </HeadingWrapper>

      <Form onSubmit={formik.handleSubmit} ref={form}>
        <GridWrapper container>

          <Grid item md={6}>
            <MuiTextField
              error={Boolean(
                formik.touched.firstName && formik.errors.firstName
              )}
              fullWidth
              required
              size='small'
              helperText={formik.touched.firstName && formik.errors.firstName}
              margin='normal'
              name='firstName'
              placeholder='first Name'
              title='first Name'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type='text'
              value={formik.values.firstName}
              variant='outlined'
            />
          </Grid>

          <Grid item md={6}>
            <MuiTextField
              error={Boolean(
                formik.touched.middleName && formik.errors.middleName
              )}
              fullWidth
              size='small'
              helperText={formik.touched.middleName && formik.errors.middleName}
              margin='normal'
              name='middleName'
              placeholder='middle Name'
              title='middle Name'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type='text'
              value={formik.values.middleName}
              variant='outlined'
            />
          </Grid>

          <Grid item md={6}>
            <MuiTextField
              error={Boolean(
                formik.touched.lastName && formik.errors.lastName
              )}
              fullWidth
              required
              size='small'
              helperText={formik.touched.lastName && formik.errors.lastName}
              margin='normal'
              name='lastName'
              placeholder='last Name'
              title='last Name'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type='text'
              value={formik.values.lastName}
              variant='outlined'
            />
          </Grid>

          <Grid item md={6}>
            <MuiTextField
              error={Boolean(
                formik.touched.email && formik.errors.email
              )}
              fullWidth
              required
              size='small'
              helperText={formik.touched.email && formik.errors.email}
              margin='normal'
              name='email'
              placeholder='email'
              title='email'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type='text'
              value={formik.values.email}
              variant='outlined'
            />
          </Grid>

          <Grid item md={6}>
            <MuiTextField
              error={Boolean(
                formik.touched.mobile && formik.errors.mobile
              )}
              fullWidth
              size='small'
              helperText={formik.touched.mobile && formik.errors.mobile}
              margin='normal'
              required
              name='mobile'
              placeholder='mobile'
              title='mobile'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type='text'
              value={formik.values.mobile}
              variant='outlined'
            />
          </Grid>

          <Grid item md={6}>
            <MuiTextField
              error={Boolean(
                formik.touched.find && formik.errors.find
              )}
              fullWidth
              required
              size='small'
              helperText={formik.touched.find && formik.errors.find}
              margin='normal'
              name='find'
              placeholder='where did you find us ?'
              title='where did you find us ?'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type='text'
              value={formik.values.find}
              variant='outlined'
            />
          </Grid>

          <Grid item md={6}>
            <MuiTextField
              error={Boolean(
                formik.touched.currentCtc && formik.errors.currentCtc
              )}
              fullWidth
              required
              size='small'
              helperText={formik.touched.currentCtc && formik.errors.currentCtc}
              margin='normal'
              name='currentCtc'
              placeholder='Current CTC'
              title='Current CTC'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type='text'
              value={formik.values.currentCtc}
              variant='outlined'
            />
          </Grid>
          <Grid item md={6}>
            <MuiTextField
              error={Boolean(
                formik.touched.expectedCtc && formik.errors.expectedCtc
              )}
              fullWidth
              required
              size='small'
              helperText={formik.touched.expectedCtc && formik.errors.expectedCtc}
              margin='normal'
              name='expectedCtc'
              placeholder='Expected CTC'
              title='Expected CTC'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type='text'
              value={formik.values.expectedCtc}
              variant='outlined'
            />
          </Grid>

          <Grid item md={6}>
            <MuiSelect
              error={Boolean(
                formik.touched.source && formik.errors.source
              )}
              fullWidth
              required
              size='small'
              data={COMMON.SOURCE}
              margin='normal'
              name='source'
              tooltip='Where did you find this candidate?'
              placeholder='Source'
              title='Source'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik?.values?.source}
              variant='outlined'
            />
          </Grid>

          <Grid item md={12}>
            <UploadFile
              fullWidth
              required
              size='small'
              margin='normal'
              name='resume'
              placeholder='Kindly upload a PDF file, first browse it and then upload the file to complete the process'
              onChange={(d) => onChangeResume(d)}
              type='text'
              error={
                (formik.touched.resume && !formik.values.resume) && 'Resume is required!'
              }
              value={formik.values.resume}
              variant='outlined'
              color='primary'
            />
          </Grid>
          <ButtonWrapper>
          <MuiButton
            color='primary'
            variant='outlined'
            size='small'
            type='button'
            // to={ROUTES.ROUTE_CANDIDATE_LIST}
            onClick={() => {
              history(-1)
            }}
            style={{ marginRight: '16px' }}
          >
            Cancel
          </MuiButton>
          <MuiButton
            color='primary'
            variant='contained'
            size='small'
            type='submit'
            isSubmitting={isLoading}
            onClick={formik.handleSubmit}
          >
            Submit
          </MuiButton>
        </ButtonWrapper>
        </GridWrapper>
      </Form>
    </PostLogin>
  )
}

export default CandidateAddPage
