
import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Grid, Avatar } from '@mui/material'
import PropTypes from 'prop-types'
import PostLogin from '../../../layout/post-login'
import { ROUTES, COMMON } from '../../../constants'
import { useMuiSnackbar } from '../../../hooks'
import JobService from '../../../services/jobOpenings.service'
import { Loader, MuiButton, MuiChip } from '../../../components'
import PersonIcon from '@mui/icons-material/Person'
import StarRateIcon from '@mui/icons-material/StarRate'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import SchoolIcon from '@mui/icons-material/School'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined'
import TravelExploreIcon from '@mui/icons-material/TravelExplore'

import {
  Heading,
  ListItemTextStyled,
  ViewBox,
  ViewWrapper,
  RhsListBox,
  RteWrapper,
  RhsListWrapper,
  MuiButtonWrapper,
  SkilsWrapper,
  Text,
  JobBoxWrapper,
  ChipWrapper,
  SubHeading,
  CommonWrapper
} from './style'

import { ButtonWrapper } from '../../common/style'

const JobViewPage = () => {
  const [jobData, setJobData] = useState({})
  const [companyData, setCompanyData] = useState({})
  const [gridLoader, setGridLoader] = useState(true)
  const { id } = useParams()
  const { SnackbarError } = useMuiSnackbar()

  useEffect(() => {
    JobService.getOneJob(id)
      .then((resp) => {
        setJobData(resp?.data.jobDetails)
        setCompanyData(resp?.data.companyDetails)
      })
      .catch((error) => {
        SnackbarError(error)
      })
      .finally(() => {
        setGridLoader(false)
      })
  }, [])

  return (
    <PostLogin heading='Job View'>
      {/* <Title
        primaryButton={{
          to: ROUTES.ROUTE_JOB_LIST,
          title: 'back',
          size: 'small',
          color: 'primary'
        }}
      /> */}
      <ViewWrapper container spacing={2} style={{ paddingTop: '15px' }}>
        <Grid item md={8}>
          <CommonWrapper className='productDesignerImage'></CommonWrapper>
          <CommonWrapper className='productDesignerImageLogo'></CommonWrapper>
          <ViewBox className='lhsWrapper'>
            <Loader showLoader={gridLoader} showMessage={(jobData?.length === 0 && !gridLoader) && true} message='No data found!' />
            <Heading>
              {jobData?.heading}
            </Heading>
            <SubHeading>
              <PersonIcon />{jobData?.jobOpenings} Vacancy ({jobData?.candidateApplied} Applied)
            </SubHeading>
            <JobBoxWrapper>
            <RhsList
                primary='Experience'
                secondary={jobData?.experience}
              />
              <RhsList
                primary='Employee type'
                secondary={COMMON.VAL_TO_LABEL('JOB_TYPE', jobData, 'jobType')}
                />
              <RhsList
                secondary={'Senior'}
                primary='Position'
              />
              <RhsList
                secondary={`${jobData?.minSalary} - ${jobData?.maxSalary}`}
                primary='Offer Salary'
              />
            </JobBoxWrapper>
            {/* <Heading> Job Description </Heading> */}
            {/* <ListItemTextStyled sx={{ flex: 'none' }} primary='job Description' /> */}
            <RteWrapper dangerouslySetInnerHTML={{ __html: jobData?.jobDescription }} />
              <SkilsWrapper>Skils Required</SkilsWrapper>
              <ChipWrapper>
                {jobData?.skills?.replaceAll('/', ',').split(',').map((skill, i) => {
                  return (
                    <MuiChip color='secondary' label={skill} key={i} variant='contained' size='small' />
                  )
                })}
              </ChipWrapper>
          </ViewBox>
        </Grid>
        <Grid item md={4} >
        <ViewBox className='rhsWrapper'>
            <Loader showLoader={gridLoader} showMessage={(jobData?.length === 0 && !gridLoader) && true} message='No data found!' />
            <RhsListWrapper>
              <Heading>
                {'job overview'}
              </Heading>
              <RhsList
                icon={<PersonIcon />}
                primary='Job Title'
                secondary={jobData?.heading}
              />
              <RhsList
                icon={<StarRateIcon />}
                primary='Experience'
                secondary={jobData?.experience}
              />
              <RhsList
                icon={<LocationOnIcon />}
                primary='location Name'
                secondary={jobData?.location?.name}
              />
              <RhsList
                icon={<AttachMoneyIcon />}
                primary='Salary Range'
                secondary={`${jobData?.minSalary} - ${jobData?.maxSalary}`}
              />
              <RhsList
                icon={<PersonIcon />}
                primary='department Name'
                secondary={jobData?.department?.name}
              />
              <RhsList
                icon={<SchoolIcon />}
                primary='Qualifications'
                secondary={jobData?.qualifications?.name}
              />
              <RhsList
                icon={<AccessTimeIcon />}
                primary='Dated Posted'
                secondary={jobData?.dated?.name || 'Posted 2 hours ago'}
              />
              <RhsList
                icon={<TravelExploreIcon />}
                primary='Openings'
                secondary={jobData?.jobOpenings}
              />
              {/* <RhsList
                icon={<AccessTimeIcon />}
                primary='Skills Required'
                secondary={jobData?.skills?.replaceAll('/', ', ')}
              /> */}
            </RhsListWrapper>
            <MuiButtonWrapper>
              <MuiButton to={`${ROUTES.ROUTE_CANDIDATE_FORM}?departmentId=${jobData?.department?._id}&jobId=${jobData?._id}`} color="primary" target='_blank' variant='contained' fullWidth >Apply Now</MuiButton>
            </MuiButtonWrapper>
          </ViewBox>
          <ViewBox className='rhsWrapper'>
            <CommonWrapper className='imageWrapper'></CommonWrapper>
            <Heading>
              {companyData.companyName}
            </Heading>
            <Text>
              {/* Since July 2017 */}
            </Text>
            <RhsList
              icon={<PhoneInTalkIcon />}
              primary='Phone'
              secondary={companyData.phone}
            />
            <RhsList
              icon={<MailOutlineIcon />}
              primary='Email'
              secondary={companyData.email}
            />
            <RhsList
              icon={<LanguageOutlinedIcon />}
              primary='Website'
              secondary={companyData.website}
              />
            <RhsList
              icon={<LocationOnOutlinedIcon />}
              primary='Location'
              secondary={companyData.location}
              />
              <MuiButtonWrapper>
                <MuiButton href={companyData.linkedin} color="primary" target='_blank' variant='contained' fullWidth>View Profile</MuiButton>
              </MuiButtonWrapper>
          </ViewBox>
        </Grid>
        <Grid item md={12}>
          <ViewBox>
            <ButtonWrapper>
              <MuiButton size='small' type='button' color="primary" variant='outlined' style={{ marginRight: '16px' }} to={ROUTES.ROUTE_JOB_LIST}>Back</MuiButton>
            </ButtonWrapper>
          </ViewBox>
        </Grid>
      </ViewWrapper>
    </PostLogin>
  )
}

export default JobViewPage

const RhsList = ({ icon, primary, secondary }) => {
  return (
    <>
      {secondary && <RhsListBox>
        <Avatar>{icon}</Avatar>
        <ListItemTextStyled
            primary={primary}
            secondary={secondary}
          />
      </RhsListBox>}
    </>
  )
}

RhsList.propTypes = {
  icon: PropTypes.node,
  primary: PropTypes.string,
  secondary: PropTypes.string
}
