import { styled, Chip } from '@mui/material'

export const ChipStyled = styled(Chip)(props => `
  text-transform: capitalize;
  font-size: 11px;
  padding: 5px 8px;
  margin: 0 2px;
  background: #766df4;
  > span {
    display: flex;
    align-items: center;
    padding: 0;
  }
`)
