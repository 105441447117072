import { styled, Slide, Snackbar } from '@mui/material'

export const SlideWrapper = styled(Slide)(props => `
  background: ${props?.variant === 'success'
  ? `${props?.theme?.palette?.success?.main}`
  : props?.variant === 'error'
  ? `${props?.theme?.palette?.error?.main}`
  : props?.variant === 'warning'
  ? `${props?.theme?.palette?.warning?.main}`
  : props?.variant === 'info'
  ? `${props?.theme?.palette?.info?.main}`
  : `
    white;
    color: ${props?.theme?.palette?.primary?.main};
    border: 1px solid ${props?.theme?.palette?.primary?.main};
  `
};
`)

export const SnackbarWrapper = styled(Snackbar)(props => `
  >div{
    color: black;
    background: ${props?.variant === 'success'
    ? `${props?.theme?.palette?.success?.main}`
    : props?.variant === 'error'
    ? `${props?.theme?.palette?.error?.main}; color: white;`
    : props?.variant === 'warning'
    ? `${props?.theme?.palette?.warning?.main}`
    : props?.variant === 'info'
    ? `${props?.theme?.palette?.info?.main}`
    : `
      white;
      color: ${props?.theme?.palette?.primary?.main};
      border: 1px solid ${props?.theme?.palette?.primary?.main};
    `};
  }
`)
