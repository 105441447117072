import { useMemo, useReducer } from 'react'
import { ACTIONS } from '../actions'
import { reducers } from '../reducer'

const ls = JSON.parse(localStorage.getItem('user'))

const initialState = {
  user: null || ls,
  token: null,
  theme: 0,
  companyId: null || ls
}

export const useUserProvider = () => {
  const [state, dispatch] = useReducer(reducers.userReducer, initialState)

  const value = useMemo(() => {
    return {
      user: state.user,
      token: state.token,
      theme: state.theme,
      companyId: state?.user?.companyId,
      setUser: (user) => {
        dispatch({ type: ACTIONS.SET_USER, user })
      },
      setToken: (token) => {
        dispatch({ type: ACTIONS.SET_TOKEN, user: token })
      },
      setTheme: (theme) => {
        dispatch({ type: ACTIONS.SET_THEME, user: theme })
      },
      setCompany: (company) => {
        dispatch({ type: ACTIONS.SET_COMPANYID, company })
      }
    }
  }, [state])

  return value
}
