import { ACTIONS } from '../actions'

export const userReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.SET_USER: {
      return {
        ...state,
        user: action.user,
        isLoading: false,
        isAuthenticated: !!action?.user?.UserId
      }
    }
    case ACTIONS.SET_TOKEN: {
      return {
        ...state,
        token: action.token,
        isLoading: false,
        isAuthenticated: !!action?.token?.Token
      }
    }
    case ACTIONS.SET_COMPANYID: {
      return {
        ...state,
        companyId: action.companyId,
        isLoading: false,
        isAuthenticated: !!action?.companyId?.CompanyId
      }
    }
    default:
      return state
  }
}
