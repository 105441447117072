import React, { useState } from 'react'
import PropTypes from 'prop-types'
import MuiButton from '../muiButton'
import { postLogin } from '../../util/axios'
import { FormLabel, TextFieldWrapper, FormError, LabelWrapper, FilePreview, FilePreviewWrapper, FileName } from './style'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault'

const UploadFile = (props) => {
  const { title, placeholder, name, error, onChange, value, required } = props
  const [file, setFile] = useState()

  const saveFile = (e) => {
    setFile(e.target.files[0])
  }

  const removeFile = () => {
    onChange({})
  }

  const uploadFile = async (e) => {
    const formData = new FormData()
    formData.append('file', file)
    try {
      const res = await postLogin.post('upload', formData)
      onChange(res.data)
    } catch (ex) {
      // console.log(ex)
    }
  }

  return (
    <>
      {title && <LabelWrapper>
        {title && <FormLabel>{title}</FormLabel>}
      </LabelWrapper>}
      {name &&
        <TextFieldWrapper className='TextFieldWrapper'>
          {!value && <FilePreviewWrapper>
            <MuiButton variant='outlined' size='small' component='label' style={{ marginRight: '15px' }}>Browse
              <input hidden multiple type='file' onChange={saveFile} />
            </MuiButton>
            <MuiButton variant='contained' size='small' id={`id_${name}`} onClick={uploadFile}>Upload File</MuiButton>
          </FilePreviewWrapper>}
          {!value && <FileName>{placeholder}{required && <span style={{ color: 'red' }}> *</span>}</FileName>}
          {value &&
            <FilePreviewWrapper>
              <FilePreview>
                {(value.extension.indexOf('pdf') !== -1) && <PictureAsPdfIcon size='small' color='#5a5278' />}
              </FilePreview>
              <FilePreview onClick={removeFile}><DisabledByDefaultIcon size='small' color='#5a5278' /></FilePreview>
            </FilePreviewWrapper>
          }
          {value && <FileName>File Name - {value?.originalFileName}</FileName>}
          {error && <FormError>{error}</FormError>}
        </TextFieldWrapper>}
    </>
  )
}

UploadFile.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  InputProps: PropTypes.string,
  dataType: PropTypes.string,
  title: PropTypes.string,
  name: PropTypes.string,
  inputProps: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  required: PropTypes.bool
}

export default UploadFile
