import {
  styled,
  Typography,
  Grid
  // Box
} from '@mui/material'

export const Upside = styled(Typography)(`
  text-transform: capitaliz;
  display: flex;
  align-items: center;
`)

export const Form = styled('form')(`
  display:flex;
  justify-content: center;
  align-items: center;
`)

export const HeadingWrapper = styled('form')(`
  background: #eae9fd;
  padding: 16px;
  margin: 13px 0 24px 0;
  color: #766df4;
`)

export const GridWrapper = styled(Grid)(`
  // color: #3d454e !important;
  display: flex;
  justify-content: space-between;
  padding: 24px 20px;
  box-shadow: 0 3px 10px 0 rgba(49,64,71,.08);
  & >div:last-child{
    justify-content: end;
  }
  & >div{
    padding: 4px 0;
    & >div{
      & >div:nth-of-type(1){
        padding: 0 0 8px 0;
      }
    }
  }
`)
