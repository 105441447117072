import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'

export const HeaderOuterWrapper = styled(Box)(`
    display: flex;
    align-items: center;
    min-height: 65px;
    width: 100%;
    background: #2e3538;
    padding: 20px 0;
    border-top: 1px solid #343c3f;
    margin-top:30px;
`)

export const HeaderInnerWrapper = styled(Box)(`
    display: flex;
    width: 100%;
    justify-content: space-between;
    color: rgba(255,255,255,.5)!important;
`)

export const Container = styled(Box)(`
    margin: 0 auto;
    padding: 0 10px;
    max-width: 90%;
    width: 100%;
`)
