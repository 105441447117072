import { styled } from '@mui/system'
import { Box, Select, MenuItem } from '@mui/material'

export const SelectWrapper = styled(Box)(props => `
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: ${!props?.compact ? '16px' : '0'};

  & div > fieldset {
    border-radius: 4px;
  }
  & div > input {
    border-radius: 4px;
    font-size: 14px;
    padding: 8px 13px;
  }
  & div > input::placeholder {
    font-size: 14px;
  }
`)

export const Wrapper = styled(Box)(() => `
  padding: 0 8px;
`)

export const FormLabel = styled('label')(`
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 600;
  color: #495057;
`)

export const LabelWrapper = styled(Box)(`
  display: flex;
  justify-content: space-between;
  align-items: center;
`)

export const MenuItemStyled = styled(MenuItem)(props => `
  font-size: 14px;
`)

export const SelectStyled = styled(Select)(props => `
  // border: ${props?.error ? `1px solid ${props?.theme?.palette?.error?.main}` : 'revert'};
  > div {
    position: relative;
    font-size: 14px !important;
    padding: 7px 13px !important;
    ${props?.minwidth && `min-width: ${props?.minwidth}`};
    &::after{
      position: absolute;
      text-transform: capitalize;
      opacity: .5;
      content: '${props?.placeholder || ''}';
    }
  }
`)

export const FormError = styled(Box)(`
  font-size: 0.75rem;
  font-weight: 400;
  margin: 4px 14px 0 14px;
  color: #D14343;
`)

export const FormSelectWrap = styled(Box)(`
  position: relative;
`)

export const InputLabel = styled(Box)(props => `
  margin-left: 15px;
  margin-bottom: -30px;
  margin-top: 0;
  opacity: 50%;
  font-size: 14px;
  z-index: 0;
  position: absolute;
  width: fit-content;
  line-height: 40px;
  height: 100%;
  display: flex;
  align-items: center;
`)
