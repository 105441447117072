import { styled, Typography, ListItemText, Paper, Grid, Box } from '@mui/material'

export const ListItemTextStyled = styled(ListItemText)(`
  width: 100%;
  > span {
    text-transform: capitalize;
    position: relative;
    width: fit-content;
    &:before{
      content:': ';
      position: absolute;
      right: -5px;
      top: 0;
    }
  }
`)

export const ViewWrapper = styled(Grid)(`
  & p{
    font-size: 14px !important:
  }
  & >div:nth-of-type(2){
      padding: 16px 0 0 44px;
      & >div:nth-of-type(2){
        align-items: center;
      }
    }
    & >div>div:nth-of-type(3){
      border-radius: 0 0 8px 8px;
    }
`)

export const RhsListWrapper = styled(Typography)(`
`)

export const SkilsWrapper = styled(Typography)(`
    border-top: 1px #eff0f2 solid;
    width: 100%;
    font-size: 14px;
    font-weight: bold;
    margin-top: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
`)

export const ViewBox = styled(Paper)(props => `
  background: white;
  display: flex;
  flex-direction: column;
  padding: 20px;
  height: auto;
  margin: 0 0 24px 0;
  border: 1px #eff0f2 solid;
  border-radius: 8px;
  }
`)

export const RhsListBox = styled(Box)(props => `
  width: 100%;
  padding: 10px  0;
  display: flex;
  align-items: flex-start;
  margin: 0;
  & > div:nth-of-type(1){
    background-color: #e4e2fd;
    paddign: 46px;
    margin: 0 16px 0 0;
  }
  & > div:nth-of-type(2){
    margin: 0;
    & span{
      font-size: 14px;
      font-weight: bold;
    }
  }
  & svg path{
    fill: #746df3;
  }
`)

export const RteWrapper = styled(Box)(props => `
  & * {font-size:14px;}
  & p { padding: 0 0 30px 0; }
  & ul {list-style: square; padding-bottom:15px;}
  & ul li {list-style:outside; padding: 0 0 5px 0; margin-left: 20px;}
  & ul li ul {list-style:circle; padding-bottom:8px;}

  & h2 { font-size: 24px; padding-bottom:8px; }
  & h3 { font-size: 18px; padding-bottom:8px; }
  & h4 { font-size: 18px; padding-bottom:8px; }
  & h5 { font-size: 13.5px; padding-bottom:8px; }
  & h6 { font-size: 13.5px; padding-bottom:8px; }
`)

export const Text = styled(Box)(props => `
  padding: 0 0 16px 0;
`)

export const MuiButtonWrapper = styled(Box)(props => `
  width: 100%;
  padding: 24px 0 0 0;
  button {
    line-height: 31px;
  }
`)

export const CommonWrapper = styled(Box)(props => `
  background-size: cover;
  background-repeat: no-repeat;
  &.productDesignerImage{
    background: url(https://themesdesign.in/jobcy-react/layout/assets/images/job-detail.jpg) no-repeat center center;
    box-shadow: 0px 1px 1px rgba(100, 116, 139, 0.06), 0px 1px 2px rgba(100, 116, 139, 0.1);
    border: 1px #eff0f2 solid;
    border-radius: 8px 8px 0 0;
    margin: 0 0 -6px 0;
    width: 100%;
    height: 300px;
  }

  &.imageWrapper{
    background: url(https://themesdesign.in/jobcy-react/layout/assets/images/featured-job/img-02.png) no-repeat center center;
    padding: 26px;
    margin: 0 0 24px 0;
    max-width: 40px;
    height: 55px;
  }
`)

export const JobBoxWrapper = styled(Box)(props => `
  display: flex;
  margin: 24px 0;
  & >div{
    border: 1px #eff0f2 solid;
    padding: 16px;
    margin: 8px 4px 8px 4px;
    >div:nth-of-type(1){
      display:none;
    }
  }
  & >div:first-of-type{
    margin: 8px 4px 8px 0;
  }
  & >div:last-of-type{
    margin: 8px 0 8px 4px;
  }
`)

export const Heading = styled(Typography)(`
  font-size: 19px;
  font-weight: bold;
  padding: 0 0 4px 0;
  text-transform: capitalize;
`)

export const SubHeading = styled(Typography)(`
  display: flex;
  align-items: center;
  font-size:15px;
  svg{
    width: 15px;
    margin-right:5px;
  }
  svg path{
    fill: #74788d;
  }
`)

export const ChipWrapper = styled(Typography)(`
  display: flex;
`)

export const JobHeading = styled(Typography)(`
  margin: 0 0 16px 0;
`)

export const HeadingWrapper = styled(Typography)(`

`)
