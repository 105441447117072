import { ACTIONS } from '../actions'

export const todoReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.ADD_TODO_ITEM:
      return {
        todoList: [
          ...state.todoList,
          action.todoItemObject
        ]
      }
    case ACTIONS.REMOVE_TODO_ITEM: {
      const filteredTodoItem = state.todoList.filter(
        (todoItem) => todoItem.id !== action.todoItemId
      )
      return { todoList: filteredTodoItem }
    }
    case ACTIONS.TOGGLE_COMPLETED: {
      const updatedTodoList = state.todoList.map((todoItem) =>
        todoItem.id === action.todoItemId
          ? { ...todoItem, completed: !todoItem.completed }
          : todoItem
      )
      return { todoList: updatedTodoList }
    }
    default:
      return state
  }
}
