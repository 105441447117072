import React from 'react'
import PreLogin from '../../layout/pre-login'
import { MuiButton } from '../../components'

const title = '404'

const NotFound = () => {
  return (
    <PreLogin titleBig={title} titleH4={'Oops.. You just found an error page..'} contentp={'We are sorry but our service is currently not available…'}>
      <MuiButton
        margin='dense'
        variant='contained'
        size='xSmall'
        to={'/'}
        sx={{ mb: '15px' }}
      >
        go back
      </MuiButton>
    </PreLogin>
  )
}

export default NotFound
