import React from 'react'
import PropTypes from 'prop-types'
import MuiButton from '../muiButton'
import { IconButton, Tooltip } from '@mui/material/'
import { ViewList, GridView } from '@mui/icons-material/'
import {
  TabsWrapper,
  TabItems,
  TabLinks,
  TitleWrapper,
  HeadingWrapper,
  HeadingTitle,
  LinksWrapper,
  HeadingSubtitle,
  LinkStyled,
  ViewTypeWrapper
} from './style'

const Title = (props) => {
  return (
    <TitleWrapper
      {...props}
    >

      {props?.tabs &&
        <TabsWrapper>
          {props?.tabs?.map((t, i) => {
            return (
              <li key={i} onClick={t?.onClick}>
                {t?.to
                  ? <TabLinks to={t?.to} className={t?.active && 'active'}>
                    {t?.label}
                  </TabLinks>
                  : <TabItems className={t?.active && 'active'}>
                    {t?.label}
                  </TabItems>
                }
              </li>
            )
          })}
        </TabsWrapper>
      }

      {props?.heading &&
        <HeadingWrapper>
          <HeadingTitle>
            {props?.heading?.title}
          </HeadingTitle>
          <HeadingSubtitle>
            {props?.heading?.subtitle}{' '}
            {props?.heading?.link && <LinkStyled to={props?.heading?.to}>{props?.heading?.link}</LinkStyled>}
          </HeadingSubtitle>
        </HeadingWrapper>
      }
      {(props?.viewtype || props?.secondaryButton || props?.primaryButton || props?.restbutton) &&
        <LinksWrapper>
          {props?.viewtype &&
            <ViewTypeWrapper onClick={props?.onViewChange}>
              <Tooltip title={`${props?.viewtype === 'list' ? 'Grid' : 'List'} view`}>
                <IconButton>
                  {props?.viewtype !== 'list'
                    ? <ViewList color='primary' />
                    : <GridView color='primary' />
                  }
                </IconButton>
              </Tooltip>
            </ViewTypeWrapper>
          }
          {props?.restbutton}
          {props?.secondaryButton &&
            <MuiButton
              style={{ marginLeft: '10px' }}
              variant={props?.secondaryButton?.variant || 'outlined'}
              type={props?.secondaryButton?.type || 'button'}
              to={props?.secondaryButton?.to}
              startAdornment={props?.secondaryButton?.startAdornment}
              endAdornment={props?.secondaryButton?.endAdornment}
              onClick={props?.secondaryButton?.onClick}
              size={props?.secondaryButton?.size}
            >
              {props?.secondaryButton?.title}
            </MuiButton>
          }
          {props?.primaryButton &&
            <MuiButton
              style={{ marginLeft: '20px' }}
              variant={props?.primaryButton?.variant || 'contained'}
              type={props?.primaryButton?.type || 'button'}
              to={props?.primaryButton?.to}
              startAdornment={props?.primaryButton?.startAdornment}
              endAdornment={props?.primaryButton?.endAdornment}
              onClick={props?.primaryButton?.onClick}
              size={props?.primaryButton?.size}
            >
              {props?.primaryButton?.title}
            </MuiButton>
          }
        </LinksWrapper>}
    </TitleWrapper>
  )
}

Title.propTypes = {
  tabs: PropTypes.array,
  to: PropTypes.string,
  heading: PropTypes.object,
  viewtype: PropTypes.string,
  buttonClick: PropTypes.func,
  onViewChange: PropTypes.func,
  size: PropTypes.string,
  primaryButton: PropTypes.object,
  secondaryButton: PropTypes.object,
  restbutton: PropTypes.node,
  startAdornment: PropTypes.node || PropTypes.string,
  endAdornment: PropTypes.node || PropTypes.string
}

export default Title
