// import React, { useState } from 'react'
// import { MuiSnackbar } from '../components'
import { useSnackbar } from 'notistack'

export const useMuiSnackbar = () => {
  const { enqueueSnackbar } = useSnackbar()
  // const [snack, setSnack] = useState({ open: false, message: '', variant: '' })

  const SnackbarSuccess = (name, mode) => {
    const text = mode === 'edit'
      ? 'updated'
      : mode === 'add'
        ? 'added'
        : mode
    // console.log(`${name} ${text} successfully`)
    enqueueSnackbar(`${name} ${text} successfully!`, {
      variant: 'success'
    })
    // setSnack({
    //   open: true,
    //   message: `${name} ${mode === 'edit' ? 'updated' : 'added'} successfully`,
    //   variant: 'success'
    // })
    // return (
    //   <MuiSnackbar open={snack.open} duration={5000} message={snack.message} variant={snack.variant} onClose={() => { setSnack({ open: false }) }} />
    // )
  }

  const SnackbarError = (error) => {
    console.error('useSnackbar ERROR: ', error)
    enqueueSnackbar(`Error: ${typeof error === 'string' ? error : error.code === 'ERR_NETWORK' ? 'Network Connection Lost' : (error?.response?.data?.message || error.response.data.error || error.response.data.error_message || error.error_message || error?.message || error?.response?.message)} (${error?.response?.status === undefined ? '404' : error?.response?.status})`, {
      variant: 'error'
    })
  }

  return { SnackbarSuccess, SnackbarError }
}
