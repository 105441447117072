import React, { useMemo } from 'react'
import { ACTIONS } from '../actions'
import { reducers } from '../reducer'

const initialState = {
  todoList: []
}

export const useTodoProvider = () => {
  const [state, dispatch] = React.useReducer(reducers.todoReducer, initialState)

  const value = useMemo(() => {
    return {
      todoList: state.todoList,
      addTodoItem: (todoItemObject) => {
        dispatch({ type: ACTIONS.ADD_TODO_ITEM, todoItemObject })
      },
      removeTodoItem: (todoItemId) => {
        dispatch({ type: ACTIONS.REMOVE_TODO_ITEM, todoItemId })
      },
      markAsCompleted: (todoItemId) => {
        dispatch({ type: ACTIONS.TOGGLE_COMPLETED, todoItemId })
      }
    }
  }, [state])

  return value
}
