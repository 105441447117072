import { styled } from '@mui/system'
import { Box, Button as Btn } from '@mui/material'
import { Link as Lnk } from 'react-router-dom'

export const Button = styled(Btn)((props) => `
  box-sizing: border-box;
  text-transform: capitalize;
  transition: ease-in-out .5s;
  ${(props?.color === 'secondary' && props?.variant === 'outlined') && `color: ${props?.theme?.palette?.secondary?.color}`};
  ${(props?.color === 'light') && 'color: #000; border: 1px solid #E7E7E7'};
  ${props?.minwidth === 'true' && 'min-width: 140px'};
  ${props?.fullwidth === 'true' && 'min-width: 100%'};
  ${(localStorage.getItem('button-style') === 'gradient' && props?.variant === 'contained' && !(props?.isSubmitting || props?.disabled)) && `background: ${props?.theme?.palette?.primary?.gradient};`}

  :hover{
    transform: translateY(-6px);
    transform: width 2s, height 2s;
  }

`)

export const StartAdornmentBox = styled(Box)(`
  display: flex;
  margin-right: 8px;
  margin-left: 0px;
`)

export const EndAdornmentBox = styled(Box)(`
  display: flex;
  margin-right: 0px;
  margin-left: 8px;
`)

export const Link = styled(Lnk)(`
  text-decoration: none;
  min-width: fit-content;
  & button {
    text-decoration: none;
  }
`)
