import { styled, Box, Select, MenuItem } from '@mui/material'

export const Wrapper = styled(Box)(`
  position: relative;
`)

export const ListWrapper = styled(Box)(`
  position: absolute;
  top: 20px;
  left: 0;
  width: 100%;
  background: white;
  z-index: 3;
`)

export const SelectStyled = styled(Select)(props => `
  min-width: 70px;
  height: 30px;
  font-size: inherit;
  position: relative;
  &:before {
    content: ${!props?.value && `'${props?.placeholder}'`};
    opacity: .5;
    position: absolute;
    left: 0;
  }
  > fieldset {
    border: none;
  }
  > div {
    z-index: 100;
    padding-right: 22px !important;
    padding-left: 0;
  }
  > svg {
    right: 0;
  }
`)

export const ListItem = styled(MenuItem)(props => `
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: start;
  &.disabled{
    pointer-events: none;
  }
  &.l2{
    padding-left: 30px;
  }
`)
