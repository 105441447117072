import React from 'react'
import PropTypes from 'prop-types'
import { useUserProvider } from './user'
import { useTodoProvider } from './todo'
import { useEmployeeProvider } from './employee'

export const AppGlobalContext = React.createContext()

export const Provider = ({ children }) => {
  const userProvider = useUserProvider()
  const todoProvider = useTodoProvider()
  const employeeProvider = useEmployeeProvider()

  const value = {
    ...userProvider,
    ...todoProvider,
    ...employeeProvider
  }

  return (
    <AppGlobalContext.Provider value={value}>
      {children}
    </AppGlobalContext.Provider>
  )
}

Provider.propTypes = {
  children: PropTypes.node
}
