import { styled } from '@mui/system'
import { Box } from '@mui/material'

export const FormLabel = styled('label')(`
  text-transform: capitalize;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #262928;
  width: 100%;
`)

export const FormError = styled(Box)(`
  font-size: 0.75rem;
  font-weight: 400;
  margin: 4px 14px 0 14px;
  color: #D14343;
`)

export const CheckBoxWrapper = styled(Box)(`
  padding: 0 8px;
  display:flex;
  flex-direction:column;
`)

export const RadioGroupWrap = styled(Box)(`
  & span:last-child {font-size: 14px;}
`)
