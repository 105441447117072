import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Select, MenuItem, Chip, Checkbox, ListItemText, OutlinedInput } from '@mui/material'
import {
  MenuItemStyled,
  FormSelectWrap,
  SelectWrapper,
  FormError,
  InputLabel,
  Wrapper,
  SelectStyled
} from './style'
import {
  LabelWrapper,
  FormLabel
} from '../common/style'
import { FormReq } from '../muiTextField/style'

const MuiSelect = (props) => {
  return (
    <Wrapper>
      {props.title && <LabelWrapper><FormLabel title={`${props?.required ? props?.tooltip : 'This is a required field!'}`} bold={props?.bold}>{props.title}{props?.required && <FormReq> *</FormReq>}</FormLabel></LabelWrapper>}
      <FormSelectWrap>
        {props?.data && props?.type !== 'MULTIPLE_SELECT' && <SingleSelect {...props} />}
        {props?.data && props?.type === 'MULTIPLE_SELECT' && <MultiSelect {...props} />}
      </FormSelectWrap>
      {props?.error && <FormError>{props?.error}</FormError>}
    </Wrapper>
  )
}

export default MuiSelect

const SingleSelect = (props) => {
  const {
    data,
    name,
    onChange,
    selectlabel = 'label',
    selectvalue = 'value',
    size,
    onSelectChange,
    required,
    compact,
    placeholder,
    children,
    value
  } = props

  const [selectedValue, setSelectedValue] = useState(value || '')
  const handleChange = (event) => {
    setSelectedValue(event.target.value)
    const selectedData = { value: event.target.value, label: event.target.label || event.target.name }
    onChange(event, selectedData)
    onSelectChange && onSelectChange(event)
  }

  // useEffect(() => {
  //   setSelectedValue(value)
  // }, [value])

  return (
    <SelectWrapper compact={compact}>
      <SelectStyled {...props} name={name} onChange={handleChange} size={size} placeholder={((!selectedValue && !value) && placeholder) && `${props?.placeholder}${props?.required ? ' *' : ''}`}>
        {!required && <MenuItemStyled value='' selected='Select'>
          <i>Select</i>
        </MenuItemStyled>}
        {children ||
          data?.map((s, i) => {
            return (
              <MenuItemStyled key={i} value={s[selectvalue]} selected={s[selectvalue] === selectedValue}>
                {s[selectlabel]}
              </MenuItemStyled>
            )
          })
        }
      </SelectStyled>
    </SelectWrapper>
  )
}

const MultiSelect = (props) => {
  const {
    data,
    name,
    onChange,
    selectlabel,
    selectvalue,
    size,
    placeholder
  } = props

  const ITEM_HEIGHT = 48
  const ITEM_PADDING_TOP = 8

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
      }
    }
  }

  const [selectedValue, setSelectedValue] = useState()
  const [selectedOptionValue, setSelectedOptionValue] = useState([])
  const [selectedOptionObject, setSelectedOptionObject] = useState([])

  const handleChange = (event) => {
    const { target: { value } } = event
    setSelectedOptionValue(typeof value === 'string' ? value.split(',') : value)
    setSelectedValue(event.target.value)

    const selectedData = data.filter(d => (value.indexOf(_value(d)) !== -1))
    setSelectedOptionObject(selectedData)

    onChange(event, selectedData)
  }

  const _value = (option) => {
    return option?.[selectvalue] || option?.value
  }
  const _label = (option) => {
    return option?.[selectlabel] || option?.label
  }

  const selectedChip = () => {
    return (
      <>
        {selectedOptionObject.map(sData => {
          return <Chip sx={{ height: '24px', mr: 1 }} key={_value(sData)} label={_label(sData)} />
        })}
      </>
    )
  }

  return (
    <>
      {!selectedValue && placeholder && <InputLabel>{placeholder}</InputLabel>}
      <SelectWrapper>
        <Select
          multiple
          value={selectedOptionValue}
          name={name}
          onChange={handleChange}
          input={<OutlinedInput label='Tag' />}
          renderValue={selectedChip}
          MenuProps={MenuProps}
          label=''
          size={size}
        >
          {data.map((option, index) => (
            <MenuItem key={index} value={_value(option)}>
              <ListItemText primary={_label(option)} />
              <Checkbox checked={selectedOptionValue.indexOf(_value(option)) > -1} />
            </MenuItem>
          ))}
        </Select>
      </SelectWrapper>
    </>
  )
}

MuiSelect.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
  type: PropTypes.bool,
  children: PropTypes.node,
  bold: PropTypes.bool,
  tooltip: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.string
}

SingleSelect.propTypes = {
  data: PropTypes.array,
  value: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  children: PropTypes.node,
  required: PropTypes.bool,
  compact: PropTypes.bool,
  onSelectChange: PropTypes.func,
  selectlabel: PropTypes.string,
  selectvalue: PropTypes.string,
  size: PropTypes.string,
  placeholder: PropTypes.string
}

MultiSelect.propTypes = {
  data: PropTypes.array,
  name: PropTypes.string,
  onChange: PropTypes.func,
  children: PropTypes.node,
  selectlabel: PropTypes.string,
  selectvalue: PropTypes.string,
  size: PropTypes.string,
  placeholder: PropTypes.string
}
