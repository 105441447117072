import React, {
  // useEffect,
  useState
} from 'react'
import PropTypes from 'prop-types'
import { TextFieldWrapper, FormError, TextFieldStyled, TextareaInput, Wrapper, FormReq } from './style'
import { LabelWrapper, FormLabel } from '../common/style'

const MuiTextField = ({ inputType, ...props }) => {
  const [inputError, setInputError] = useState()
  const maxLen = props?.InputProps?.maxLength || props?.InputProps?.max || 0

  const validate = (event) => {
    let val = event.target.value

    if (props?.dataType === 'number') {
      val = val.replace(/[^0-9]/g, '')
    } else if (props?.dataType === 'decimal') {
      let decimalCount = 0
      val = val
        .replace(/[^0-9.]/g, '')
        .replace(/\./g, match => ++decimalCount > 1 ? '' : match)
    }

    if (maxLen > 0 && val.length > maxLen) {
      setInputError(`Max length is ${maxLen}`)
      val = val.slice(0, maxLen)
      setTimeout(() => {
        setInputError('')
      }, 4000)
    }
  }

  return (
    <Wrapper>
      {props.title && <LabelWrapper>{props.title && <FormLabel title={`${props?.required ? 'This is a required field!' : ''}`} bold={props?.bold}>{props.title}{props?.required && <FormReq> *</FormReq>}</FormLabel>}
        {props?.endAdornment && props?.endAdornment}</LabelWrapper>}

      {props.name &&
        <TextFieldWrapper className='TextFieldWrapper'>
          {
            inputType === 'area'
              ? <TextareaInput minRows={3} {...props} />
              : <TextFieldStyled
                {...props}
                placeholder={`${props?.placeholder}${props?.required ? ' *' : ''}`}
                value={props?.value}
                onKeyDown={(e) => validate(e)}
                onKeyUp={(e) => validate(e)}
                autoComplete='off'
                inputProps={{ ...props?.inputProps, maxLength: maxLen || null }}
              />
          }

        </TextFieldWrapper>}
      {(inputError || (inputType === 'area' && props.error)) && (<FormError>{inputError || props.error} ----</FormError>)}
    </Wrapper>
  )
}

MuiTextField.propTypes = {
  placeholder: PropTypes.string,
  inputType: PropTypes.string,
  required: PropTypes.bool,
  bold: PropTypes.bool,
  value: PropTypes.string,
  InputProps: PropTypes.string,
  dataType: PropTypes.string,
  title: PropTypes.string,
  name: PropTypes.string,
  endAdornment: PropTypes.node,
  inputProps: PropTypes.string,
  error: PropTypes.bool
}

export default MuiTextField
