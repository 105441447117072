import React, { useEffect } from 'react'
// import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { ROUTES } from '../constants'
import AuthService from '../services/auth.service'
import EventBus from '../common/EventBus'
import {
  NotFound,
  InternalServer,
  Candidate,
  Job
} from '../pages'

const BackOfficeRouter = props => {
  // const [showModeratorBoard, setShowModeratorBoard] = useState(false)
  // const [showAdminBoard, setShowAdminBoard] = useState(false)
  // const [currentUser, setCurrentUser] = useState(undefined)

  useEffect(() => {
    const user = AuthService.getCurrentUser()
    if (user) {
      // setCurrentUser(user)
      // setShowModeratorBoard(user.roles.includes('ROLE_MODERATOR'))
      // setShowAdminBoard(user.roles.includes('ROLE_ADMIN'))
    }
    const logOut = () => {
      AuthService.logout()
      // setShowModeratorBoard(false)
      // setShowAdminBoard(false)
      // setCurrentUser(undefined)
    }

    EventBus.on('logout', () => {
      logOut()
    })

    return () => {
      EventBus.remove('logout')
    }
  }, [])

  return (
    <Router>
      <Routes>
        <Route path={ROUTES.ROUTE_INDEX} element={<Job.JobListPage />} />
        <Route path={ROUTES.ROUTE_LOGIN} element={<Job.JobListPage />} />
        <Route path={ROUTES.ROUTE_NOTFOUND} element={<NotFound />} />
        <Route path={ROUTES.ROUTE_SERVER} element={<InternalServer />} />

        <Route path={ROUTES.ROUTE_JOB_LIST} element={<Job.JobListPage />} />
        <Route path={ROUTES.ROUTE_JOB_FORM} element={<Job.JobAddPage />} />
        <Route path={`${ROUTES.ROUTE_JOB_FORM}/:id`} element={<Job.JobAddPage />} />
        <Route path={`${ROUTES.ROUTE_JOB_VIEW}/:id`} element={<Job.JobViewPage />} />

        <Route path={ROUTES.ROUTE_CANDIDATE_FORM} element={<Candidate.CandidateAddPage />} />
        <Route path={`${ROUTES.ROUTE_CANDIDATE_FORM}/:id`} element={<Candidate.CandidateAddPage />} />
        <Route path={`${ROUTES.ROUTE_CANDIDATE_FORM}?:id`} element={<Candidate.CandidateAddPage />} />
        <Route path={`${ROUTES.ROUTE_CANDIDATE_VIEW}/:id`} element={<Candidate.CandidateViewPage />} />
        <Route path={`${ROUTES.ROUTE_CANDIDATE_VIEW}?:id`} element={<Candidate.CandidateViewPage />} />

        <Route path={ROUTES.ROUTE_NOTFOUND_404} element={<NotFound />} />
      </Routes>
    </Router>
  )
}

export default BackOfficeRouter
