import React, {
  useRef
  // useState,
  // useEffect
} from 'react'
import PropTypes from 'prop-types'
import {
  PreLoginOuterWrapper,
  PreLoginInnerWrapper,
  CardWrapper,
  RhsWrapper,
  LhsWrapper,
  // RhsBox,
  // RhsBgImg,
  // RhsTitle,
  // RhsPara,
  BelowButton,
  TitleH1,
  LogoWrapper,
  ContentP,
  TitleH4,
  TitleBig
} from './style'
import KeyboardCommandKeyOutlinedIcon from '@mui/icons-material/KeyboardCommandKeyOutlined'

const PreLogin = ({ children, heading1, logo = false, belowDescription, contentp, titleBig, titleH4, linkStyled, formControlLabelStyled }) => {
  const carouselWrapper = useRef(null)
  // const carousel = [
  //   {
  //     title: 'Cross Browser Compatibility',
  //     className: 'pre-login-slide0',
  //     img: 'https://epichr.nsdbytes.com/assets/images/slider1.svg',
  //     para: 'Overview We\'re a group of women who want to learn JavaScript.'
  //   },
  //   {
  //     title: 'Cross Browser Compatibility',
  //     img: 'https://epichr.nsdbytes.com/assets/images/slider2.svg',
  //     para: 'Overview We\'re a group of women who want to learn JavaScript.'
  //   },
  //   {
  //     title: 'Cross Browser Compatibility',
  //     img: 'https://epichr.nsdbytes.com/assets/images/slider3.svg',
  //     para: 'Overview We\'re a group of women who want to learn JavaScript.'
  //   }
  // ]

  return (
    <PreLoginOuterWrapper>
      <PreLoginInnerWrapper>
        <LhsWrapper>
          <CardWrapper>
            {logo && <LogoWrapper>
              <KeyboardCommandKeyOutlinedIcon sx={{ color: '#5a5278', height: '34px', width: '34px' }} />
            </LogoWrapper>}
            {heading1 && <TitleH1>{heading1}</TitleH1>}
            {titleBig && <TitleBig>{titleBig}</TitleBig>}
            {titleH4 && <TitleH4>{titleH4}</TitleH4>}
            {contentp && <ContentP>{contentp}</ContentP>}
            {children}
            {belowDescription && <BelowButton>{belowDescription}</BelowButton>}
          </CardWrapper>
        </LhsWrapper>
        <RhsWrapper ref={carouselWrapper}>
          {/* {carousel.map((d, i) => {
            return (
              <RhsBox key={i} className={d?.className}>
                <RhsBgImg src={d?.img} />
                <RhsTitle>{d?.title}</RhsTitle>
                <RhsPara>{d?.para}</RhsPara>
              </RhsBox>
            )
          })
          } */}
        </RhsWrapper>
      </PreLoginInnerWrapper>
    </PreLoginOuterWrapper>
  )
}

PreLogin.propTypes = {
  children: PropTypes.node,
  heading1: PropTypes.string,
  contentp: PropTypes.string,
  titleBig: PropTypes.string,
  titleH4: PropTypes.string,
  linkStyled: PropTypes.string,
  formControlLabelStyled: PropTypes.string,
  logo: PropTypes.boolean,
  belowDescription: PropTypes.node
}

PreLogin.displayName = 'PreLogin'

export default PreLogin
