import { styled } from '@mui/material/styles'
import { Menu, Box } from '@mui/material'

const style = `
  display: flex;
  flex-direction: column;
  z-index: 1600;
  overflow: visible;
  color: black;
  box-shadow: 0 2px 20px 0 rgb(0 0 0 / 25%);
  border: 0;
  font-size: 14px;
  border-radius: 4px;
  padding: 8px 0;
  min-width: 192px;
  background: white;
  &:after {
    position: absolute;
    content: '';
    top: 0;
    right: 3px;
    height: 10px;
    width: 10px;
    transform: translate(0%, -100%);
    border: 10px transparent solid;
    border-bottom: 10px white solid;
  }
`

export const DropBoxWrapper = styled(Box)(({ theme }) => `
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 9;
`)

export const DropBoxOld = styled(Box)(({ theme }) => `
  position: absolute;
  right: 0;
  top: 30px;
  ${style}
`)

export const DropBox = styled(Menu)(({ theme }) => `
  > .MuiMenu-paper {
    ${style}
  }
`)
