// import axios from 'axios'
import { preLogin } from '../util/axios'
// import Cookies from 'universal-cookie'
// const cookies = new Cookies()
// import { useGlobalState } from '../hooks'

const API_URL = `${process.env.REACT_APP_API_URL}`
const ROLE = 'admin'

const registerApi = data => {
  data.role = ROLE
  // const url = `${API_URL}users/register`
  const url = `${API_URL}auth/signup`
  return preLogin.post(url, data)
}

const loginApi = data => {
  const url = `${API_URL}auth/signin`
  data.role = ROLE
  return preLogin.post(url, data)
    .then((response) => {
      if (response?.data?.accessToken) {
        const local = response?.data
        // sessionStorage.setItem('token', JSON.stringify(response?.data?.accessToken))
        localStorage.setItem('token', JSON.stringify(local?.accessToken))
        delete local?.accessToken
        localStorage.setItem('user', JSON.stringify(local))
      }
      return response?.data
    })
}

const logout = () => {
  localStorage.removeItem('user')
  // sessionStorage.removeItem('token')
  localStorage.removeItem('token')
  const url = `${API_URL}auth/signout`
  return preLogin.post(url)
}

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem('user'))
}

export default {
  logout,
  getCurrentUser,
  loginApi,
  registerApi
}
