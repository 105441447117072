import React from 'react'
import {
  HeaderOuterWrapper,
  Container,
  HeaderInnerWrapper,
  LogoWrapper,
  Shadow
} from './style'

const Header = () => {
  return (
    <>
      <HeaderOuterWrapper>
        <Container>
          <HeaderInnerWrapper>
            <LogoWrapper>
              <img src='https://apphubs.in/img/apphubs-logo2.png' alt='Apphubs' />
            </LogoWrapper>
          </HeaderInnerWrapper>
        </Container>
      </HeaderOuterWrapper>
      <Shadow>
        <svg viewBox="0 0 500 60" height="60px" width="500px" xmlns="http://www.w3.org/2000/svg" data-bx-workspace="master">
          {/* <defs>
            <linearGradient gradientUnits="userSpaceOnUse" x1="250" y1="0" x2="250" y2="50" id="gradient-0">
              <stop offset="0" style="stop-color: rgb(70, 58, 236);"></stop>
              <stop offset="1" style="stop-color: rgb(96, 85, 242);"></stop>
            </linearGradient>
          </defs> */}
          <path
          // style="stroke-width: 0px; paint-order: fill; stroke: rgb(0, 0, 0); fill: url('#gradient-0'); fill-rule: nonzero;"
          fill="#6055f2"
          d="M 0 0 L 500 0 L 500 4.941 C 500 4.941 436 14 400 25 C 357 35 305 50 250 50 C 195 50 142 37 100 25 C 55 10 0 4.941 0 4.941 L 0 0 Z"
          ></path>
        </svg>
      </Shadow>
    </>
  )
}

export default Header
