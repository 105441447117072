import { styled } from '@mui/material/styles'
import { Box, Container } from '@mui/material'

export const PostLoginOuterWrapper = styled(Box)(({ theme }) => `
  display: flex;
  flex-direction: column;
  // flex: 1 1 auto;
  max-width: 100%;
  justify-content: space-between;
  height:100vh;
`)

export const LhsWrapper = styled(Box)(props => `
  display: flex;
  transition: ease-in-out .5s;
  width: ${props?.theme?.components?.MuiSideBar?.width};
  min-height: 100vh;
  overflow: unset;
  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
    background: white;
    z-index: 1200;
  }
`)

export const RhsWrapper = styled(Box)(({ theme }) => `
  // height: 100vh;
  // transition: ease-in-out .5s;
  display: flex;
  width: calc(100% - ${theme?.components?.MuiSideBar?.width});
  flex-direction: column;
`)

export const ContentWrapper = styled(Container)(`
`)

export const __ContentWrapper = styled(Box)(({ theme }) => `
  display: flex;
  // overflow-y: scroll;
  flex: 1 1 auto;
  display:flex;
  border:2px red solid;
  flex-direction: column;
  width: 100%;
  background: ${theme.palette.background.index};
  padding: 20px 36px;
  padding-top: 0;

  .container {
      max-width: 1140px!important;
  }

  @media (min-width: 1400px){
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        max-width: 1320px;
    }
  }
  @media (min-width: 1200px){
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 1140px;
    }
  }
  @media (min-width: 992px){
    .container, .container-lg, .container-md, .container-sm {
        max-width: 960px;
    }
  }
  @media (min-width: 768px){
    .container, .container-md, .container-sm {
        max-width: 720px;
    }
  }
  @media (min-width: 576px){
    .container, .container-sm {
        max-width: 540px;
    }
  }

`)
