import { styled, Box, Card } from '@mui/material'
const compensate = '48px'

export const CardWrapper = styled(Card)(props => `
  border: 1px #eff0f2 solid;
  border-radius: 6px;
  display: flex;
  padding: 24px;
  margin: calc(${compensate} / 2);
  transition: all .5s ease-in-out;
  &:hover {
    transform: translateY(-6px);
    border: 1px #746df3 solid;
  }
  & >div:nth-of-type(2){
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`)

export const Text = styled(Box)(props => `
  padding: 0 8px 0 0;
`)

export const ContentHeading = styled(Box)(props => `
  padding: 0 0 4px 0;
  font-weight: bold;
`)

export const ContentWrapper = styled(Box)(props => `
  display: flex;
  align-items: center;
  padding: 0 0 8px 0;
`)

export const CardInnerWrapper = styled(Box)(props => `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  width: 100%;
`)
