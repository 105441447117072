import axios from 'axios'
import { ROUTES } from '../constants'

// import { useGlobalState } from '../hooks'
// const { token } = useGlobalState()
// const jwtToken = token || JSON.parse(sessionStorage.getItem('token'))

const jwtToken = JSON.parse(localStorage.getItem('token'))

export const preLogin = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`
})

export const postLogin = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  headers: { token: jwtToken }
})

postLogin.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
postLogin.defaults.headers.common.mode = 'cors'
postLogin.defaults.headers.post['Content-Type'] = 'text/json'

preLogin.interceptors.request.use(
  (req) => {
    // Do something before request is sent
    return req
  },
  (err) => {
    // Do something with request error
    return Promise.reject(err)
  }
)

preLogin.interceptors.response.use(
  (res) => {
    return res
  },
  (err) => {
    return Promise.reject(err)
  }
)

postLogin.interceptors.request.use(
  (req) => {
    // Do something before request is sent
    req.headers.token = JSON.parse(localStorage.getItem('token')) // temporary fix
    return req
  },
  (err) => {
    // Do something with request error
    return Promise.reject(err)
  }
)

postLogin.interceptors.response.use(
  (res) => {
    return res
  },
  (err) => {
    if (err.response.status === 403) {
      location.replace(`${ROUTES.ROUTE_LOGIN}?to=${window.location.pathname}`)
    }
    return Promise.reject(err)
  }
)
