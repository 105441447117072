export const ROUTE_INDEX = '/'
export const ROUTE_LOGIN = '/login'
export const ROUTE_SIGNUP = '/signup'
export const ROUTE_FORGOT_PASSWORD = '/forgotpassword'
export const ROUTE_NOTFOUND = '/404'
export const ROUTE_SERVER = '/500'
export const ROUTE_NOTFOUND_404 = '/*'

export const ROUTE_JOB_LIST = '/jobs/list'
export const ROUTE_JOB_FORM = '/jobs/form'
export const ROUTE_JOB_VIEW = '/jobs/view'

export const ROUTE_CANDIDATE_LIST = '/candidates/list'
export const ROUTE_CANDIDATE_FORM = '/candidates/form'
export const ROUTE_CANDIDATE_VIEW = '/candidates/view'
