import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'
import Header from '../../components/header'
import Footer from '../../components/footer'
import {
  PostLoginOuterWrapper,
  ContentWrapper
} from './style'

const PostLogin = ({
  children
}) => {
  return (
    <PostLoginOuterWrapper className='postlogin-wrapper'>
      <Box>
        <Header />
        <ContentWrapper>
          {children}
        </ContentWrapper>
      </Box>
      <Footer />
    </PostLoginOuterWrapper>
  )
}

PostLogin.propTypes = {
  heading: PropTypes.string || PropTypes.node,
  sidefixed: PropTypes.bool,
  breadcrumbs: PropTypes.array,
  fixed: PropTypes.bool,
  children: PropTypes.node
}

PostLogin.displayName = 'PostLogin'

export default PostLogin
