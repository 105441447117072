import { styled, ButtonGroup, ListItemText, Paper } from '@mui/material'

export const ButtonGroupStyled = styled(ButtonGroup)(props => `
  border-radius: ${props?.theme?.components?.MuiButton?.styleOverrides?.root?.borderRadius}px;
  ${(localStorage.getItem('button-style') === 'gradient' && props?.variant === 'contained' && !(props?.isSubmitting || props?.disabled)) &&
  `background: ${props?.theme?.palette?.primary?.gradient};
    > button {
      background: transparent;
    }
  `};
  ${props?.size === 'chip'
    ? `
      > button {
        padding: 1px 6px;
        font-size: 11px;
        &.arrow {
        }
        > svg {
          font-size: 14px;
        }
      }
    `
    : ''
  }
`)

export const PaperStyled = styled(Paper)(props => `
  ${props?.size === 'chip'
  ? `
    > ul {
      padding: 3px 0;
      > li {
        padding: 3px 10px;
        > div {
          > span {
            font-size: 12px;
          }
        }
      }
    }
    `
  : ''
  };
`)

export const ListItemTextStyled = styled(ListItemText)(`
  text-transform: capitalize;
  > span {
    display: flex;
    font-size: 15px;
    align-items: center;
    > svg {
      margin-right: 6px;
    }
  }
  > p {
    font-size: 12px;
  }
`)
