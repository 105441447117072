import React from 'react'

// import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import { theme } from './theme'
import { CssBaseline } from '@mui/material'
import { SnackbarProvider } from 'notistack'

import BackOfficeRouter from './router'
import './App.css'

const App = () => {
  const themeIndex = localStorage.getItem('theme') || 0
  return (
    // <BrowserRouter>
    <SnackbarProvider>
      <ThemeProvider theme={theme[themeIndex]}>
        <CssBaseline />
        <BackOfficeRouter />
      </ThemeProvider>
    </SnackbarProvider>
    // </BrowserRouter>
  )
}

export default App
