import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'

export const HeaderOuterWrapper = styled(Box)(({ theme }) => `
    display: flex;
    align-items: center;
    height: 65px;
    background: #fff;
    width: 100%;
    box-shadow:0 5px 10px 0 rgba(49,64,71,.3);
    position:relative;
    z-index:1;
`)

export const LogoWrapper = styled(Box)(`
    display: flex;
    height: 40px;
`)

export const HeaderInnerWrapper = styled(Box)(`
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
`)

export const Container = styled(Box)(`
    margin: 0 auto;
    padding: 0 10px;
    max-width: 90%;
    width: 100%;
`)

export const Shadow = styled(Box)(`
    width:100%;
    height:60px;
    overflow:hidden;
    & svg {
        transform:scale(4, 1) translate(0, -6px);
        width:100%;
    }
`)
